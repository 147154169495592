<template>
  <div>
    <span v-if="this.$route.name === 'team-europe'"></span>
    <span v-else>
      <Sponsors /> 
    </span>
    <div class="footer">
      <b-container>
        <b-row>
          <b-col cols="9">
            <b-nav-item class="footMenu" to='/about-letas-tour'>About LETAS</b-nav-item>
            <b-nav-item class="footMenu" to='/contact-let'>Contact LETAS</b-nav-item>
            <b-nav-item class="footMenu" to='/privacy-policy'>Privacy Policy LETAS</b-nav-item>
            <b-nav-item class="footMenu" to='/careers'>Careers</b-nav-item>
            <!-- <b-nav-item class="footMenu" href='https://ocs-let.com/let-members/'><i class="fas fa-user portal-logo"></i>Player Portal</b-nav-item> -->
          </b-col>
          <b-col cols="3">
            <b-row>
              <b-col lg="2">
                <b-nav-item class="menuBar" href="https://www.tiktok.com/@letaccess?lang=en" target="_blank">
                  <font-awesome-icon class="icon" :icon="['fab', 'tiktok']" /> 
                </b-nav-item>
              </b-col>
              <b-col lg="2">
                <b-nav-item class="menuBar" :href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME" target="_blank">
                  <font-awesome-icon class="icon" :icon="['fab', 'facebook']" /> 
                </b-nav-item>
              </b-col>
              <b-col lg="2">
                <b-nav-item class="menuBar" :href="'https://twitter.com/'+config.VUE_APP_TWITTER_NAME" target="_blank">
                  <img class='newTwitter' src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/logo-white.png">
                </b-nav-item>
              </b-col>
              <b-col lg="2">
                <b-nav-item class="menuBar" :href="'https://www.instagram.com/'+config.VUE_APP_INSTA_NAME" target="_blank">
                  <font-awesome-icon
                    class="icon"
                    :icon="['fab', 'instagram']"
                  />
                </b-nav-item>
              </b-col>
              <b-col lg="2">
                <b-nav-item class="menuBar" :href="'https://www.youtube.com/user/'+config.VUE_APP_YOUTUBE_NAME" target="_blank">
                  <font-awesome-icon class="icon" :icon="['fab', 'youtube']" />
                </b-nav-item>
              </b-col>
              <b-col lg="2">
                <b-nav-item class="menuBar" :href="'https://www.flickr.com/photos/'+config.VUE_APP_FLICKR_NAME" target="_blank">
                  <font-awesome-icon class="icon" :icon="['fab', 'flickr']" />
                </b-nav-item>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="reg">
          <b-col>
            <p class="footerText">
              Ladies European Golf Venture Limited, a private limited company
              registered in England
            </p>
            <p class="footerText">
              Registration number: 12352756 – VAT registration GB 340 4610 40
            </p>
            <p class="footerText">
              Registered Office: Buckinghamshire Golf Club, Denham Court Dr.
            </p>
            <p class="footerText">Buckinghamshire, United Kingdom UB9 5PG</p>
          </b-col>
          <b-col>
            <b-link href="https://ocs-sport.com/#/" target="_blank">
              <b-img
                class="ocs"
                :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/12/OCS_Sport_Logo_2017_WO.png'"
              ></b-img>
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Sponsors from "@/components/sponsors.vue";
export default {
  name: "Footer",
  props: ["season", 'config'],
  components: { Sponsors },
  data() {
    return {
      footerData: [],
    };
  },
};
</script>

<style scoped>
i.fas.fa-user.portal-logo {
  font-size: 14pt;
  margin-top: 10px;
  padding-right: 10px;
}
img.newTwitter {
  width: 16px;
}
::v-deep .title {
  position: absolute;
  left: 15px;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.footer {
  background-color: #7ba639;
  padding: 10px 0;
}
.footMenu {
  font-size: 9pt;
  line-height: 1.5;
}
.footMenu > a {
  color: white;
  padding: 0;
}
.menuBar > a {
  color: white;
}
li {
  list-style: none;
}
.footerText {
  text-transform: initial;
  font-size: 9pt;
  line-height: 0.3;
  color: white;
}
.reg {
  padding: 30px 0 0 0;
}
.ocs {
  float: right;
  width: 100px;
}
@media only screen and (max-width: 425px) {
  ::v-deep .title {
    padding-left: 20px;
  }
  ::v-deep .footerText {
    text-transform: initial;
    font-size: 9pt;
    line-height: 1.3;
    color: white;
  }
}

</style>
